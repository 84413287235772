
















































































import Vue from "vue";
import EntityCandidate from "@/components/widgets/EntityCandidate.vue";
import EntitySpan from "@/components/widgets/EntitySpan.vue";
import KnnTable from "@/components/tables/KnnTable.vue";

import * as backend from "@/funcs/backend-requests";
import * as docs from "@/resources/docs/docs";

export default Vue.extend({
  name: "TraitMapping",
  components: {
    EntityCandidate,
    EntitySpan,
    KnnTable,
  },
  data() {
    return {
      docs: docs,
      // entity search things ----
      entityToSearch: null,
      entityAcQuery: null,
      entityOptions: [],
      isEntitySearchLoading: false,
      // entity search things ----
      dictionary: null,
      dictionaryTarget: null,
      dictionaryOptions: [],
      embeddingType: null,
      embeddingTypeOptions: ["bge", "llama3"],
      topK: 15,
      // results
      knnItems: [],
    };
  },
  computed: {
    entSelectDisabled(): boolean {
      return !this.dictionary;
    },
    submitBtnDisabled(): boolean {
      const enabled =
        this.entityToSearch &&
        this.dictionary &&
        this.dictionaryTarget &&
        this.topK &&
        this.embeddingType;
      return !enabled;
    },
    entSelectLabel() {
      const ready = "Search and select an entity";
      const notReady = "Choose a dictionary first";
      if (this.entSelectDisabled) {
        return notReady;
      } else {
        return ready;
      }
    },
  },
  watch: {
    async entityAcQuery(val): Promise<void> {
      if (!val) return;
      if (val.length < 3) return;
      if (this.isEntitySearchLoading) return;
      this.isEntitySearchLoading = true;
      this.entityOptions = await backend.getEntityOptions(val, this.dictionary);
      this.isEntitySearchLoading = false;
    },
  },
  mounted: async function (): Promise<void> {
    this.embeddingType = "bge";
    this.dictionaryOptions = await backend.getDictionaryOptions();
  },
  methods: {
    async submit(): Promise<void> {
      this.knnItems = await backend.getKnn(
        this.entityToSearch,
        this.dictionary,
        this.dictionaryTarget,
        this.topK,
        this.embeddingType,
      );
    },
  },
});
