










import Vue from "vue";

export default Vue.extend({
  name: "Tooltip",
  props: {
    docs: {
      type: String,
      required: true,
    },
    showUnderline: {
      type: Boolean,
      default: true,
    },
    position: {
      type: String,
      default: "bottom",
    },
  },
  data() {
    return {
      //
    };
  },
});
