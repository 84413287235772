











import Vue from "vue";

export default Vue.extend({
  name: "EntityCandidate",
  props: {
    entId: String,
    entTerm: String,
    dictionary: String,
  },
  computed: {
    //
  },
  methods: {
    //
  },
});
