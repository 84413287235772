













import Vue from "vue";

export default Vue.extend({
  name: "EntityCandidate",
  props: {
    entId: {
      type: [String, Number],
    },
    entTerm: String,
    dictionary: String,
  },
  computed: {
    //
  },
  methods: {
    //
  },
});
