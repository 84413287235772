




















import Vue from "vue";
import Prism from "vue-prism-component";
import "prismjs/components/prism-python";

import TraitMapping from "@/components/TraitMapping.vue";

import * as docs from "@/resources/docs/docs";
import * as tutorialDocs from "@/resources/docs/api-examples";

export default Vue.extend({
  name: "Home",
  components: {
    TraitMapping,
    Prism,
  },
  data() {
    return {
      docs: docs,
      tutorialDocs: tutorialDocs,
      tab: 0,
    };
  },
  computed: {
    //
  },
  watch: {
    //
  },
  mounted: async function (): Promise<void> {
    //
  },
  methods: {
    //
  },
});
