



















import Vue from "vue";

import { web_backend_url } from "@/config";

export default Vue.extend({
  name: "AppBar",
  data: () => ({
    appTitle: "TraitHunter",
    apiUrl: web_backend_url,
  }),
  computed: {
    currentRouteName() {
      return this.$route.name;
    },
  },
});
