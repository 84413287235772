


















import Vue from "vue";
import { getPing } from "@/funcs/backend-requests";
import { pathOutline } from "@/resources/resources";

export default Vue.extend({
  name: "Sidebar",
  data: () => ({
    items: pathOutline,
    apiConnected: false,
  }),
  computed: {
    currentRouteName() {
      return this.$route.name;
    },
    selectedItem() {
      const res = this._.chain(this.items)
        .findIndex((e) => e.name == this.currentRouteName)
        .value();
      return res;
    },
  },
  mounted: async function () {
    this.apiConnected = await getPing();
  },
});
