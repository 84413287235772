












import Vue from "vue";
import AppBar from "@/components/AppBar.vue";
import Sidebar from "@/components/Sidebar.vue";
import SnackbarWidget from "@/components/widgets/Snackbar.vue";

export default Vue.extend({
  name: "App",
  components: {
    AppBar,
    Sidebar,
    SnackbarWidget,
  },
  data: () => ({
    //
  }),
});
