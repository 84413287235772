var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-card-title',[_c('h2',[_vm._v("Trait mapping")])]),_c('v-card-text',[_c('h3',[_vm._v("Configure search parameters")]),_c('vue-markdown',{attrs:{"source":_vm.docs.traitMappingDoc}}),_c('v-form',[_c('v-row',[_c('v-col',[_c('v-subheader',[_vm._v("Source entity")]),_c('v-select',{staticClass:"px-4",attrs:{"label":"Select dictionary","items":_vm.dictionaryOptions},model:{value:(_vm.dictionary),callback:function ($$v) {_vm.dictionary=$$v},expression:"dictionary"}}),_c('v-autocomplete',{staticClass:"px-4",attrs:{"disabled":_vm.entSelectDisabled,"label":_vm.entSelectLabel,"search-input":_vm.entityAcQuery,"loading":_vm.isEntitySearchLoading,"items":_vm.entityOptions,"item-text":"ent_term","item-value":"ent_id"},on:{"update:searchInput":function($event){_vm.entityAcQuery=$event},"update:search-input":function($event){_vm.entityAcQuery=$event}},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_c('v-list-item',[_c('v-list-item-title',[_c('span',[_vm._v("Type to search entity by its label")])])],1)]},proxy:true},{key:"selection",fn:function(ref){
var attr = ref.attr;
var on = ref.on;
var item = ref.item;
var selected = ref.selected;
return [_c('v-chip',_vm._g(_vm._b({attrs:{"input-value":selected}},'v-chip',attr,false),on),[_c('entity-span',{attrs:{"ent-id":item.ent_id,"ent-term":item.ent_term,"dictionary":item.dictionary}})],1)]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_c('entity-candidate',{attrs:{"ent-id":item.ent_id,"ent-term":item.ent_term,"dictionary":item.dictionary}})]}}]),model:{value:(_vm.entityToSearch),callback:function ($$v) {_vm.entityToSearch=$$v},expression:"entityToSearch"}}),_c('v-subheader',[_vm._v("Target entity")]),_c('v-select',{staticClass:"px-4",attrs:{"label":"Select dictionary of the target entities","items":_vm.dictionaryOptions},model:{value:(_vm.dictionaryTarget),callback:function ($$v) {_vm.dictionaryTarget=$$v},expression:"dictionaryTarget"}})],1),_c('v-col',[_c('v-subheader',[_vm._v("Other parameters")]),_c('v-select',{staticClass:"px-4",attrs:{"label":"Select embedding model","items":_vm.embeddingTypeOptions},model:{value:(_vm.embeddingType),callback:function ($$v) {_vm.embeddingType=$$v},expression:"embeddingType"}}),_c('v-subheader',[_vm._v("Top K neighbors")]),_c('div',{staticClass:"pt-4"}),_c('div',{staticClass:"pt-4"}),_c('v-slider',{staticClass:"px-4",attrs:{"ticks":"always","thumb-label":"always","min":10,"max":40,"step":5},model:{value:(_vm.topK),callback:function ($$v) {_vm.topK=$$v},expression:"topK"}})],1)],1),_c('div',{staticClass:"d-flex justify-center mb-6"},[_c('v-btn',{attrs:{"disabled":_vm.submitBtnDisabled,"color":"primary","large":""},on:{"click":_vm.submit}},[_vm._v("Submit")])],1)],1),_c('v-divider',{staticClass:"py-5"}),(_vm.knnItems.length > 0)?_c('div',[_c('h3',[_vm._v("Results")]),_c('knn-table',{attrs:{"items":_vm.knnItems}})],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }